import { Node, mergeAttributes } from '@tiptap/core'
import type { mastodon } from '#types'

interface PollOptions {
  itemTypeName: string,
  HTMLAttributes: Record<string, any>,
}

export interface IndiPollOption extends Partial<mastodon.v1.PollOption> {
  id: string
  index: number
}
/**
 * Represents a poll attached to a status.
 * @see https://docs.joinmastodon.org/entities/poll/
 */
export interface IndiPoll extends Partial<Omit<mastodon.v1.Poll, 'options'>> {
  options?: IndiPollOption[]
  title?: string
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    poll: {
      addPoll: () => ReturnType,
      updatePollAttributes: (attributes: IndiPoll) => ReturnType,
      setPollTitle: (title: string) => ReturnType,
      setPollOptions: (options: IndiPollOption[]) => ReturnType,
    }
  }
}

export const Poll = Node.create<PollOptions>({
  name: 'poll',
  draggable: true,
  group: 'block list',

  addOptions() {
    return {
      itemTypeName: 'pollItem',
      HTMLAttributes: { 'data-type': this.name }
    }
  },

  content() {
    return `${this.options.itemTypeName}+`
  },

  addAttributes() {
    return {
      /** The ID of the poll in the database. */
      id: {
        default: '',
        parseHTML: (element) => {
          return element.dataset.id ?? ''
        }
      },
      /** When the poll ends. */
      expiresAt: {
        default: null,
        parseHTML: (element) => {
          return element.dataset.expiresAt ?? null
        }
      },
      /** Does the poll allow multiple-choice answers? */
      multiple: {
        default: false,
        parseHTML: (element) => {
          return element.dataset.multiple ?? false
        }
      },
      /** non-mastodon prop  */
      anonymous: {
        default: false,
        parseHTML: (element) => {
          return element.dataset.anonymous ?? false
        }
      },
      /** How many votes have been received. */
      votesCount: {
        default: 0,
        parseHTML: (element) => {
          return element.dataset.votesCount ?? 0
        }
      },
      /** How many unique accounts have voted on a multiple-choice poll. */
      votersCount: {
        default: 0,
        parseHTML: (element) => {
          return element.dataset.votersCount ?? 0
        }
      },
      /** Possible answers for the poll. */
      options: {
        default: [],
        parseHTML: (element): IndiPollOption[] => {
          return Array.from(element.querySelectorAll('li') || []).map((listItem, index) => {
            return {
              id: listItem.dataset.optionId ?? crypto.randomUUID(),
              title: listItem.innerHTML,
              votesCount: parseInt(listItem.dataset.votesCount ?? '0'),
              index
            }
          })
        }
      },
      title: {
        default: '',
        parseHTML: (element) => {
          return element.querySelector<HTMLElement>('li:first-child')?.innerText ?? 'aaaa'
        }
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `ul[data-type="${this.name}"]`,
        priority: 51
      }
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      addPoll: () => ({ commands }) => {
        return commands.toggleList(this.name, this.options.itemTypeName, true)
      },
      updatePollAttributes: attributes => ({ commands }) => {
        return Object.entries(attributes).map(([key, value]) => commands.updateAttributes(this.name, { [key]: value })).every(result => result)
      },
      setPollTitle: title => ({ commands }) => {
        return commands.updateAttributes(this.name, { title })
      },
      setPollOptions: options => ({ commands }) => {
        return commands.updateAttributes(this.name, { options })
      }
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Shift-9': () => this.editor.commands.addPoll()
    }
  }
})
