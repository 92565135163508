<script setup lang="ts">
import { EditorContent } from '@tiptap/vue-3'
import type { mastodon } from '#types'

const props = defineProps<{
  status?: mastodon.v1.Status | null
}>()
const emit = defineEmits(['close'])

const { loggedIn } = useAuth()
const { $mediaUpload } = useNuxtApp()

const { status } = toRefs(props)
const draft = $mediaUpload?.getDraft({ status })

const currentStatusTypeClass = computed(() => `content-type-${draft.value.contentType}`)
const isPublishDisabled = computed(() => draft.value.isUploading || !draft.value.isChanged)

const { editor } = useTiptap({
  content: computed(() => draft.value.content),
  editable: !(draft.value?.isUploading ?? false),
  autofocus: true,
  onUpdate: (html, files) => {
    $mediaUpload?.updateDraft(draft.value, { html, files })
  },
})

watch([editor, draft], ([, draft]) => {
  if (editor.value?.isEditable !== !draft.isUploading) {
    editor.value?.setEditable(!draft.isUploading)
  }
})

function setVisibility(visibility: mastodon.v1.StatusVisibility) {
  $mediaUpload?.updateDraft(draft.value, { visibility })
}

function publish() {
  if (isPublishDisabled.value) {
    return
  }
  $mediaUpload?.publishDraft(draft.value)
  emit('close')
}
</script>

<template>
  <div v-if="isHydrated && loggedIn" :class="currentStatusTypeClass" class="editable">
    <Portal to="modal-header">
      <div class="flex-row-center">
        <PublishVisibilitySelect :model-value="draft.visibility" @update:model-value="setVisibility" />
        <button
          type="button"
          :disabled="isPublishDisabled"
          :aria-disabled="isPublishDisabled"
          aria-describedby="publish-tooltip"
          @click="publish">
          <SpinnerIcon v-if="draft.isUploading" />
          <span v-if="draft.isUploading">{{ $t('state-publishing') }}</span>
          <span v-else-if="draft.editingStatus">{{ $t('action-save_changes') }}</span>
          <span v-else>{{ $t('action-publish') }}</span>
          <span v-if="draft.errors.length" block>
            {{ $t('state-publish_failed') }}
          </span>
        </button>
      </div>
    </Portal>
    <TiptapBubbleMenu v-if="editor" :editor="editor" />
    <EditorContent :editor="editor" />
  </div>
</template>

<style lang="scss">
.content-box {
  container-type: inline-size;

  .content-rich {
    padding-bottom: var(--base-size);
  }
}

.content-rich {
  &.focused:after {
    opacity: 1;
  }

  &.ProseMirror {
    &-focused {
      outline: none;
    }
  }
}
</style>
