<script setup lang="ts" generic="T">
import type { Placement } from 'tippy.js'
import type { MenuItem } from '~/types'

defineProps<{
  options: MenuItem<T>[]
  placement?: Placement
}>()

const popup = ref()

function onTriggerKeyEvent (event: KeyboardEvent) {
  if (event.key === 'ArrowDown' && popup.value) {
    event.preventDefault()
    event.stopPropagation()
    popup.value.show()
    return false
  }
  if (event.key === 'ArrowUp' && popup.value) {
    event.preventDefault()
    event.stopPropagation()
    popup.value.hide()
    return true
  }
  return false
}
</script>

<template>
  <UiPopupMenu
    ref="popup"
    :items="options"
    :on-trigger-key-event="onTriggerKeyEvent">
    <template #trigger-title="{ state }">
      <span class="dropdown-select-button">
        <slot name="title">
          Dropdown
        </slot></span>
      <span v-if="!state?.isVisible" class="icon-menu-down" />
      <span v-else class="icon-menu-up" />
    </template>
  </UiPopupMenu>
</template>

<style>
.dropdown-select-button {
  padding-inline-start: var(--padding-small);
  display: flex;
  align-items: center;
}
</style>
