<script setup lang="ts">
const props = withDefaults(defineProps<{ as: any; active: boolean }>(), { as: 'div' })
const el = ref()

watch(() => props.active, (active) => {
  const _el = unrefElement(el)

  if (active && _el) {
    _el.scrollIntoView({ block: 'nearest', inline: 'start' })
  }
})
</script>

<template>
  <component :is="as" ref="el">
    <slot />
  </component>
</template>
