import type { Ref } from 'vue'
import { ref } from 'vue'

export type UseCompletionOptions = {
  /**
   * The API endpoint that accepts a `{ prompt: string }` object and returns
   * a stream of tokens of the AI completion response. Defaults to `/api/completion`.
   */
  api?: string
  /**
   * An unique identifier for the chat. If not provided, a random one will be
   * generated. When provided, the `useChat` hook with the same `id` will
   * have shared states across components.
   */
  id?: string

  /**
   * Initial prompt input of the completion.
   */
  initialInput?: string

  /**
   * Initial completion result. Useful to load an existing history.
   */
  initialCompletion?: string

  /**
   * Callback function to be called when the API response is received.
   */
  onResponse?: (response: Response) => void | Promise<void>

  /**
   * Callback function to be called when the completion is finished streaming.
   */
  onFinish?: (prompt: string, completion: string) => void

  /**
   * Callback function to be called when an error is encountered.
   */
  onError?: (error: Error) => void

  /**
   * The credentials mode to be used for the fetch request.
   * Possible values are: 'omit', 'same-origin', 'include'.
   * Defaults to 'same-origin'.
   */
  credentials?: RequestCredentials

  /**
   * HTTP headers to be sent with the API request.
   */
  headers?: Record<string, string> | Headers

  /**
   * Extra body object to be sent with the API request.
   * @example
   * Send a `sessionId` to the API along with the prompt.
   * ```js
   * useChat({
   *   body: {
   *     sessionId: '123',
   *   }
   * })
   * ```
   */
  body?: object
}

type RequestOptions = {
  headers?: Record<string, string> | Headers
  body?: object
}

export type UseCompletionHelpers = {
  /** The current completion result */
  completion: Ref<string>;
  /** The error object of the API request */
  error: Ref<undefined | Error>;
  /**
   * Send a new prompt to the API endpoint and update the completion state.
   */
  complete: (
    prompt: string,
    options?: RequestOptions
  ) => Promise<string | null | undefined>;
  /**
   * Abort the current API request but keep the generated tokens.
   */
  stop: () => void;
  /** Whether the API request is in progress */
  isLoading: Ref<boolean | undefined>;
};

let uniqueId = 0

const store: Record<string, string> = reactive({})

export function useCompletion({
  api = '/api/ai/completion',
  id,
  initialCompletion = '',
  credentials,
  headers,
  body,
  onResponse,
  onFinish,
  onError
}: UseCompletionOptions = {}): UseCompletionHelpers {
  // Generate an unique id for the completion if not provided.
  const completionId = id || `completion-${uniqueId++}`
  const key = `${api}|${completionId}`

  store[key] ||= initialCompletion

  const completion = computed<string>(() => store[key])

  const isLoading = ref(false)
  const error = ref<undefined | Error>(undefined)

  let abortController: AbortController | null = null
  async function triggerRequest(prompt: string, options?: RequestOptions) {
    try {
      isLoading.value = true
      abortController = new AbortController()

      // Empty the completion immediately.
      store[key] = ''

      const res = await fetch(api, {
        method: 'POST',
        body: JSON.stringify({
          prompt,
          ...body,
          ...options?.body
        }),
        headers: {
          ...headers,
          ...options?.headers
        },
        signal: abortController.signal,
        credentials
      })

      if (onResponse) {
        await onResponse(res)
      }

      if (!res.ok) {
        throw new Error((await res.text()) || 'Failed to fetch the chat response.')
      }
      if (!res.body) {
        throw new Error('The response body is empty.')
      }

      let result = ''
      const reader = res.body.getReader()
      const decoder = new TextDecoder()

      while (true) {
        const { done, value } = await reader.read()
        if (done) {
          break
        }
        // Update the chat state with the new message tokens.
        result += value ? decoder.decode(value, { stream: true }) : ''
        store[key] = result

        // The request has been aborted, stop reading the stream.
        if (abortController === null) {
          reader.cancel()
          break
        }
      }

      if (onFinish) {
        onFinish(prompt, result)
      }

      abortController = null
      return result
    } catch (err) {
      // Ignore abort errors as they are expected.
      if ((err as any).name === 'AbortError') {
        abortController = null
        return null
      }

      if (onError && error.value instanceof Error) {
        onError(error.value)
      }

      error.value = err as Error
    } finally {
      isLoading.value = false
    }
  }

  const complete: UseCompletionHelpers['complete'] = (prompt, options) => {
    return triggerRequest(prompt, options)
  }

  const stop = () => {
    if (abortController) {
      abortController.abort()
      abortController = null
    }
  }

  return {
    completion,
    complete,
    error,
    stop,
    isLoading
  }
}
