<script setup lang="ts">
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'

const props = defineProps(nodeViewProps)

const { startRecording, stopRecording, isRecording, recordingBlob } = useNuxtApp().$audioRecorder

function start() {
  props.editor.setEditable(false)
  startRecording()
}

function stop() {
  watchOnce(recordingBlob, (blob) => {
    if (blob) {
      const contentMedia = asContentMedia(blob)
      if (contentMedia?.id) {
        const file = new File([blob], contentMedia.id, { lastModified: new Date().getTime(), type: blob.type })
        props.editor.chain().focus().setAttachment({ ...contentMedia, file }).run()
      }
    }
  })
  props.editor.setEditable(true)
  stopRecording()
}

function deleteRecorder() {
  stopRecording()
  props.deleteNode()
  props.editor?.setEditable(true)
}
</script>

<template>
  <NodeViewWrapper>
    <div class="recorder">
      <button v-if="isRecording" @click="stop">Stop</button>
      <button v-else @click="start">Start</button>
      <button type="button" @click="deleteRecorder">x</button>
      <ContentAudioVisualizer />
    </div>
  </NodeViewWrapper>
</template>
