<script setup lang="ts">
import type { Editor, Range } from '@tiptap/vue-3'
import type { MenuItem, SlashCommandItem, UiMenuListType } from '~/types'

const props = defineProps<{
  items: SlashCommandItem[]
  command: (item: SlashCommandItem) => void
  editor: Editor
  range: Range
  isPending?: boolean
}>()

const menu = ref<UiMenuListType>()

const { complete, isLoading } = useCompletion({
  id: 'tiptap-ai',
  api: '/api/ai/completion',
  onResponse: () => {
    props.editor.chain().focus().deleteRange(props.range).run()
  },
  onFinish: (_prompt, completion) => {
    // highlight the generated text
    props.editor.commands.setTextSelection({
      from: props.range.from,
      to: props.range.from + completion.length,
    })
  },
  onError: (err) => {
    console.error(err)
  },
})

const menuItems = computed(() => {
  return props.items.map<MenuItem>((item) => {
    const { title, icon, description } = item
    const loading = (item.key === 'ai' && isLoading.value)
    return {
      label: title,
      icon: loading ? 'icon' : icon,
      desc: description,
      command: async () => {
        if (item.key === 'ai') {
          complete(getPrevText(5000, 1))
        }
        else if (item.key === 'upload') {
          props.editor.chain().focus().deleteRange(props.range).run()
          const items = await pickContentMedia()
          for (const item of items) {
            props.editor.chain().focus().setAttachment(item).run()
          }
        }
        else {
          props.command(item)
        }
      },
    }
  })
})

function onKeyDown(event: KeyboardEvent) {
  return menu.value?.onKeyDown(event)
}

function getPrevText(chars: number, offset: number) {
  return props.editor.state.doc.textBetween(
    Math.max(0, props.editor.state.selection.from - chars),
    props.editor.state.selection.from - offset,
    '\n',
  )
}

defineExpose({
  onKeyDown,
})
</script>

<template>
  <UiMenuList id="slash-command" ref="menu" :items="menuItems" :is-pending="isPending" />
</template>
