<script setup lang="ts">
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'

const props = defineProps(nodeViewProps)

const editable = computed(() => props.editor.isEditable)
const mediaItems = computed({
  get() { return props.node.attrs.mediaItems ?? [] },
  set(mediaItems) {
    props.updateAttributes({ mediaItems: [...mediaItems] })
  }
})

function toggleViewerType() {
  const type = props.node.attrs.type === 'grid' ? 'slideshow' : 'grid'
  // props.editor?.chain().focus().setViewerType(type).run()
  props.updateAttributes({ type })
}

// function removeAttachment(id: string) {
//   props.editor.chain().focus().removeAttachment(id).run()
// }

watch(mediaItems, (value) => {
  if (!value.length) {
    props.deleteNode()
  }
})

const isSlideshow = computed(() => props.node.attrs.type === 'slideshow' && props.node.attrs.mediaItems.length > 1)

async function pickMedia() {
  for (const item of await pickContentMedia()) {
    props.editor?.chain().focus().setAttachment(item).run()
  }
}
</script>

<template>
  <NodeViewWrapper
    ref="viewer"
    class="media-viewer full-width-content"
    :class="{ selected, editable, 'slideshow-box': isSlideshow }">
    <header v-if="editable && selected">
      <UiBar corner class="drag-handle button compact" contenteditable="false" data-drag-handle>
        <span class="icon-drag-dots" />
      </UiBar>
      <UiBar corner>
        <button v-if="mediaItems.length > 1" class="compact" @click="toggleViewerType">
          <span v-if="isSlideshow" class="icon-grid" />
          <span v-else class="icon-slideshow" />
        </button>
        <button class="compact" @click="pickMedia">
          <span class="icon-plus" />
        </button>
      </UiBar>
    </header>
    <ContentMediaViewer :type="props.node.attrs.type" :media-items="mediaItems" />
  </NodeViewWrapper>
</template>

<style lang='scss'>
.media-viewer {
  position: relative;
  overflow: clip; // "clip" allows children with sticky position work properly
  display: grid;
  grid-template-rows: var(--button-height), 1fr;

  header {
    grid-row: 1;
    grid-column: 1;
    margin: var(--padding-mini);
    display: flex;
    gap: var(--padding-mini);
    width: fit-content;
    height: var(--button-height);
    position: sticky;
    top: 0;
    z-index: 200;
  }

  .drag-handle {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .media-viewer {
    grid-row: 1 / 3;
    grid-column: 1;
    position: relative;
  }

  &.selected {
    position: relative;

    .media-viewer::after {
      content: '';
      display: block;
      position: absolute;
      inset: 0;
      border-block: dashed 3px color-mix(in lab, var(--color-bg), var(--color-panel));
      opacity: 0.5;
      mix-blend-mode: exclusion;
    }
  }
}
</style>
