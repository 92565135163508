import { Link } from '@tiptap/extension-link'
import { Mention } from '@tiptap/extension-mention'
import PlaceholderPlugin from '@tiptap/extension-placeholder'
import TiptapUnderline from '@tiptap/extension-underline'
import { StarterKit } from '@tiptap/starter-kit'
import type { Editor } from '@tiptap/vue-3'
import { useEditor } from '@tiptap/vue-3'
import type { Ref } from 'vue'
import { TiptapPluginMediaViewer } from './tiptap/media-viewer'
import { Poll } from './tiptap/poll'
import { PollItem as PollItemComponent } from './tiptap/poll-Item'
import SlashCommand from './tiptap/slash-command'
import { TiptapHashtagSuggestion, TiptapMentionSuggestion } from './tiptap/suggestion'
import Embed from './tiptap/embed'
import { AudioRecorder } from './tiptap/audio-recorder'

export interface UseTiptapOptions {
  content: Ref<string>
  editable: boolean
  onUpdate?: (html: string, files: File[]) => void
  autofocus: boolean
}

export function useTiptap(options: UseTiptapOptions) {
  const files = ref<File[]>([])
  if (import.meta.server) {
    return {
      editor: ref<Editor | undefined>(),
      files,
    }
  }

  const { autofocus, content, editable } = options

  const editor = useEditor({
    content: content.value,
    editable,
    extensions: [
      StarterKit.configure({
        history: {
          depth: 10,
        },
        gapcursor: false,
      }),
      TiptapUnderline,
      Mention.configure({
        suggestion: TiptapMentionSuggestion,
      }),
      Mention.extend({ name: 'hashtag' }).configure({
        suggestion: TiptapHashtagSuggestion,
      }),
      PlaceholderPlugin.configure({
        placeholder: ({ node }) => {
          if (node.type.name === 'heading') {
            return `Heading ${node.attrs.level}`
          }
          return 'Press \'/\' for commands, or \'++\' for AI autocomplete...'
        },
        includeChildren: true,
      }),
      Link.configure({
        protocols: ['mailto'],
        autolink: false,
        openOnClick: false,
        linkOnPaste: false,
      }),
      TiptapPluginMediaViewer,
      SlashCommand,
      Embed,
      Poll,
      PollItemComponent,
      AudioRecorder,
    ],
    onUpdate({ editor }) {
      // function getPrevText(chars: number) {
      //   return editor.state.doc.textBetween(
      //     Math.max(0, editor.state.selection.from - chars),
      //     editor.state.selection.from,
      //     '\n'
      //   )
      // }
      // const selection = editor.state.selection
      // const lastTwo = getPrevText(2)
      // if (lastTwo === '++' && !isLoading.value) {
      //   editor.commands.deleteRange({
      //     from: selection.from - 2,
      //     to: selection.from
      //   })
      //   complete(getPrevText(5000))
      // } else {
      // }
      if (options.onUpdate) {
        options.onUpdate(editor.getHTML(), editor.storage['media-viewer'].files)
      }
    },
    editorProps: {
      attributes: {
        class: 'content-editor content-rich',
      },
    },
    autofocus,
  })

  const { completion } = useCompletion({
    id: 'tiptap-ai',
    api: '/api/ai/completion',
    onFinish: (_prompt, completion) => {
      editor.value?.commands.setTextSelection({
        from: editor.value.state.selection.from - completion.length,
        to: editor.value.state.selection.from,
      })
    },
    onError: (err) => {
      console.error(err.message)
    },
  })

  // watch([isLoading, editor, completion], () => {
  //   const onKeyDown = (e: KeyboardEvent) => {
  //     if ((e.key === 'Escape') || (e.metaKey && e.key === 'z')) {
  //       e.preventDefault()
  //       stop()
  //       if (e.key === 'Escape') {
  //         editor.value?.commands.deleteRange({
  //           from: editor.value.state.selection.from - completion.value?.length,
  //           to: editor.value.state.selection.from
  //         })
  //       }
  //       editor.value?.commands.insertContent('++')
  //     }
  //   }
  //   const mousedownHandler = (e: MouseEvent) => {
  //     e.preventDefault()
  //     e.stopPropagation()
  //     stop()
  //     if (window.confirm('AI writing paused. Continue?')) {
  //       complete(editor.value?.getText() || '')
  //     }
  //   }
  //   if (isLoading.value) {
  //     document.addEventListener('keydown', onKeyDown)
  //     window.addEventListener('mousedown', mousedownHandler)
  //   } else {
  //     document.removeEventListener('keydown', onKeyDown)
  //     window.removeEventListener('mousedown', mousedownHandler)
  //   }
  // })

  // Insert chunks of the generated text
  let text = ''
  watch(completion, () => {
    const diff = completion.value.slice(text.length)
    text = completion.value
    editor.value?.commands.insertContent(diff)
  })

  watch(content, (value) => {
    if (editor.value?.getHTML() === value) {
      return
    }
    editor.value?.commands.setContent(value || '', false)
  })

  return {
    editor,
  }
}
