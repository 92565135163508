<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  items: mastodon.v1.Account[]
  command: Function
  isPending?: boolean
}>()

const selectedIndex = ref(0)

watch(() => props.items, () => {
  selectedIndex.value = 0
})

function onKeyDown(event: KeyboardEvent) {
  if (props.items.length === 0) {
    return false
  }

  if (event.key === 'ArrowUp') {
    selectedIndex.value = ((selectedIndex.value + props.items.length) - 1) % props.items.length
    return true
  } else if (event.key === 'ArrowDown') {
    selectedIndex.value = (selectedIndex.value + 1) % props.items.length
    return true
  } else if (event.key === 'Enter') {
    selectItem(selectedIndex.value)
    return true
  }

  return false
}

function selectItem(index: number) {
  const item = props.items[index]
  if (item) {
    props.command({ id: item.acct })
  }
}

defineExpose({
  onKeyDown
})
</script>

<template>
  <div
    v-if="isPending || items.length">
    <template v-if="isPending">
      <div>
        <span>{{ $t('common-fetching') }}</span>
      </div>
    </template>
    <template v-if="items.length">
      <CommonScrollIntoView
        v-for="(item, index) in items"
        :key="index"
        :active="index === selectedIndex"
        as="button"
        @click="selectItem(index)">
        <!-- <AccountInfo :account="item" /> -->
        {{ item.acct }}
      </CommonScrollIntoView>
    </template>
  </div>
  <div v-else />
</template>
