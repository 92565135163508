import type { Props } from 'tippy.js'

export function tippyOptions (
  placement: Props['placement'] = 'top',
  fallbackPlacements?: Props['placement'][],
  trigger: Props['trigger'] = 'manual',
  withArrow: boolean = false
): Partial<Props> {
  return {
    appendTo: () => document.body,
    showOnCreate: true,
    interactive: true,
    trigger,
    theme: 'indi',
    arrow: withArrow,
    placement,
    popperOptions: {
      modifiers: [
        {
          name: 'preventOverflow',
          options: {
            padding: 10
          }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements
          }
        }
      ]
    }
  }
}
