<script setup lang="ts">
import { nodeViewProps, NodeViewWrapper } from '@tiptap/vue-3'
import type { mastodon } from '#types'

const props = defineProps(nodeViewProps)

const isLoading = ref(false)
const card = ref<mastodon.v1.PreviewCard | undefined>(undefined)

onMounted(async () => {
  try {
    isLoading.value = true
    const url = props.node.attrs['data-embed-url']
    card.value = await $fetch<mastodon.v1.PreviewCard>('/api/microformats', {
      method: 'POST',
      body: { url },
    })
  }
  catch (err) {
    console.error(err)
    props.deleteNode()
  }
  finally {
    isLoading.value = false
  }
})
</script>

<template>
  <NodeViewWrapper>
    <p v-if="isLoading">
      loading...
    </p>
    <div>
      {{ card }}
    </div>
    <button type="button" @click="deleteNode">
      x
    </button>
  </NodeViewWrapper>
</template>
