import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import EditorMediaViewer from '~/components/tiptap/views/EditorMediaViewer.vue'
import { mediaTypeToTag } from '~/lib/tag-media-type'
import type { MediaItem } from '~/types/Attachment'
import type { ContentMedia, UploadContentMedia } from '~/types'

export const inputRegex = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/

export type MediaViewerOptions = {
  HTMLAttributes: Record<string, unknown>
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    mediaViewer: {
      setAttachment: (_media: UploadContentMedia) => ReturnType
      removeAttachment: (_options: string) => ReturnType
      setViewerType: (_options: string) => ReturnType
    }
  }
}

export const TiptapPluginMediaViewer = Node.create({
  name: 'media-viewer',
  priority: 1000,
  group: 'block',
  draggable: true,
  atom: true,

  addStorage() {
    return {
      files: [],
    }
  },

  addOptions() {
    return {
      HTMLAttributes: {},
      mini: false,
    }
  },

  addAttributes() {
    return {
      type: {
        default: 'grid',
        parseHTML: element => element.dataset.type,
      },
      mediaItems: {
        default: null,
        parseHTML: (element) => {
          if (element.tagName.toLowerCase() === 'ul') {
            return Array.from(element.querySelectorAll('img, video, audio, a')).map(elementToMedia)
          }
          return [elementToMedia(element)]
        },
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `ul[data-${this.name}], img, video, audio, a[data-file]`,
      },
    ]
  },

  renderHTML({ HTMLAttributes: { mediaItems, type: viewerType, ...attrs } }) {
    const items = (mediaItems as ContentMedia[]).map((media) => {
      const tag = mediaTypeToTag(media.type)
      if (media.type === 'video') {
        media.posterTime = 0.25
      }
      return ['li', {}, [tag, mediaAttrs(media)]]
    })
    return ['ul', mergeAttributes(mediaViewerAttrs(viewerType), attrs), ...items]
  },

  addNodeView() {
    return VueNodeViewRenderer(EditorMediaViewer)
  },

  addCommands() {
    return {
      setAttachment: (media: UploadContentMedia) => ({ editor, commands: { insertContent, updateAttributes } }) => {
        this.storage.files.push(media.file)
        if (editor.isActive(this.name)) {
          const mediaItems = [...editor.getAttributes(this.name).mediaItems] as ContentMedia[]
          const index = mediaItems.findIndex(item => item.id === media.id)
          if (index > -1) {
            mediaItems.splice(index, 1, media)
          }
          else {
            mediaItems.push(media)
          }
          return updateAttributes(this.name, { mediaItems })
        }
        else {
          return insertContent({
            type: this.name,
            attrs: {
              mediaItems: [media],
            },
          })
        }
      },

      removeAttachment: (id: string) => ({ editor, commands: { updateAttributes } }) => {
        const mediaItems = [...editor.getAttributes(this.name).mediaItems] as MediaItem[]
        const index = mediaItems.findIndex(item => item.id === id)
        if (index >= 0) {
          mediaItems.splice(index, 1)
        }
        return updateAttributes(this.name, { mediaItems })
      },

      setViewerType: (type: string) => ({ chain }) => {
        return chain()
          .updateAttributes(this.name, { type })
          .run()
      },
    }
  },
})
