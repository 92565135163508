<script setup lang="ts">
const canvas = ref<HTMLCanvasElement>()
const visualizer = useNuxtApp().$audioRecorder.audioVisualizer

watch([visualizer, canvas], ([visualizer, canvas]) => {
  if (canvas && visualizer) {
    visualizer.canvas = canvas
  }
})
</script>

<template>
  <canvas ref="canvas" class="audio-visualizer" />
</template>

<style scoped>
  canvas {
    max-width: 100%;
  }
</style>
