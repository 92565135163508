import { Suggestion } from '@tiptap/suggestion'
import { type Editor, type Range, Extension } from '@tiptap/vue-3'
import { createSuggestionRenderer } from './suggestion'
import type { SlashCommandItem } from '~/types'
import TiptapCommandList from '~/components/tiptap/views/TiptapCommandList.vue'

const suggestionItems: SlashCommandItem[] = [
  {
    key: 'upload',
    title: 'Media',
    description: 'Upload an image, video & audio',
    searchTerms: ['photo', 'picture', 'video', 'audio', 'media'],
    icon: 'icon-media',
  },
  {
    key: 'audio-recorder',
    title: 'Audio Recorder',
    description: 'Record audio',
    searchTerms: ['record', 'audio', 'media'],
    icon: 'icon-microphone',
    command: ({ editor, range }) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .startRecorder()
        .run(),
  },
  {
    key: 'ai',
    title: 'Continue writing',
    description: 'Use AI to expand your thoughts.',
    searchTerms: ['gpt'],
    icon: 'icon-brain',
  },
  {
    title: 'Text',
    description: 'Just start typing with plain text.',
    icon: 'icon-pencil',
    searchTerms: ['p', 'paragraph'],
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleNode('paragraph', 'paragraph')
        .run()
    },
  },
  {
    title: 'Heading 1',
    description: 'Big section heading.',
    searchTerms: ['title', 'big', 'large'],
    icon: 'icon-h1',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode('heading', { level: 1 })
        .run()
    },
  },
  {
    title: 'Heading 2',
    description: 'Medium section heading.',
    searchTerms: ['subtitle', 'medium'],
    icon: 'icon-h2',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode('heading', { level: 2 })
        .run()
    },
  },
  {
    title: 'Heading 3',
    description: 'Small section heading.',
    searchTerms: ['subtitle', 'small'],
    icon: 'icon-h3',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .setNode('heading', { level: 3 })
        .run()
    },
  },
  {
    title: 'Bullet List',
    description: 'Create a simple bullet list.',
    searchTerms: ['unordered', 'point'],
    icon: 'icon-list',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleBulletList()
        .run()
    },
  },
  {
    title: 'Numbered List',
    description: 'Create a list with numbering.',
    searchTerms: ['ordered'],
    icon: 'icon-list-ordered',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleOrderedList()
        .run()
    },
  },
  {
    title: 'Quote',
    description: 'Capture a quote.',
    searchTerms: ['blockquote'],
    icon: 'icon-text-quote',
    command: ({ editor, range }) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleNode('paragraph', 'paragraph')
        .toggleBlockquote()
        .run(),
  },
  {
    title: 'Code',
    description: 'Capture a code snippet.',
    searchTerms: ['codeblock'],
    icon: 'icon-code',
    command: ({ editor, range }) =>
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .toggleCodeBlock()
        .run(),
  },
  {
    key: 'poll',
    title: 'Poll',
    description: 'Create poll',
    searchTerms: ['poll'],
    icon: 'icon-poll',
    command: ({ editor, range }) => {
      editor
        .chain()
        .focus()
        .deleteRange(range)
        .addPoll()
        .run()
    },
  },
]

const getSuggestionItems = ({ query }: { query: string }) => {
  return suggestionItems.filter((item) => {
    if (typeof query === 'string' && query.length > 0) {
      const search = query.toLowerCase()
      return (
        item.title.toLowerCase().includes(search)
        || item.description.toLowerCase().includes(search)
        || (item.searchTerms && item.searchTerms.some((term: string) => term.includes(search)))
      )
    }
    return true
  })
}

const Command = Extension.create({
  name: 'slash-command',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({ editor, range, props }: { editor: Editor, range: Range, props: any }) => {
          props.command?.({ editor, range })
        },
      },
    }
  },
  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ]
  },
})

const SlashCommand = Command.configure({
  suggestion: {
    items: getSuggestionItems,
    render: createSuggestionRenderer(TiptapCommandList),
  },
})

export default SlashCommand
