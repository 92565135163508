import { type KeyboardShortcutCommand, mergeAttributes, Node } from '@tiptap/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import PollItemView from '~/components/tiptap/views/PollItemView.vue'

interface PullItemOptions {
  onReadOnlyChecked?: (node: ProseMirrorNode, checked: boolean) => boolean
  nested: boolean
  HTMLAttributes: Record<string, unknown>
  taskListTypeName: string
}

export const inputRegex = /^\s*(\[([( |x])?\])\s$/

export const PollItem = Node.create<PullItemOptions>({
  name: 'pollItem',

  addOptions() {
    return {
      nested: false,
      HTMLAttributes: {},
      taskListTypeName: 'taskList',
    }
  },

  content() {
    return this.options.nested ? 'paragraph block*' : 'paragraph+'
  },

  defining: true,
  draggable: true,

  addAttributes() {
    return {
      checked: {
        default: false,
        keepOnSplit: false,
        parseHTML: element => element.getAttribute('data-checked') === 'true',
        renderHTML: attributes => ({
          'data-checked': attributes.checked,
        }),
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: `li[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'li',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        'data-type': this.name,
      }),
      [
        'label',
        [
          'input',
          {
            type: 'checkbox',
            checked: node.attrs.checked ? 'checked' : null,
          },
        ],
        // ['span']
        ['div', 0],
      ],
    ]
  },

  addKeyboardShortcuts() {
    const shortcuts: { [key: string]: KeyboardShortcutCommand } = {
      'Enter': () => this.editor.commands.splitListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    }
    if (!this.options.nested) {
      return shortcuts
    }
    return {
      ...shortcuts,
      Tab: () => this.editor.commands.sinkListItem(this.name),
    }
  },

  addNodeView() {
    return VueNodeViewRenderer(PollItemView)
  },

  // addKeyboardShortcuts() {
  //   return {
  //     Enter: () => this.editor.commands.splitListItem(this.name),
  //     Tab: () => this.editor.commands.sinkListItem(this.name),
  //     'Shift-Tab': () => this.editor.commands.liftListItem(this.name)
  //   }
  // }
})
